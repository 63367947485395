<template>
  <v-alert
    text
    prominent
    color="primary"
    border="right"
    class="headline text-center"
  >
    <div
      class="mx-5 pre-formatted text-justify-last-center"
      v-text="value"
    />
  </v-alert>
</template>

<script>
export default {
  name: 'QuestionAlert',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .pre-formatted {
    white-space: pre-wrap;
  }

  .text-justify-last-center {
    text-align: justify;
    text-align-last: center;
  }
</style>
