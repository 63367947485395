<template>
  <v-row align="center" justify="center" class="mt-10">
    <v-col cols="auto">
      <h2 v-text="$t('ontime.selectLabel')" />
    </v-col>
    <v-col md="6">
      <v-select
        :value="value"
        :items="items"
        solo
        hide-details
        prepend-inner-icon="mdi-clock-outline"
        @input="$emit('input', $event)"
      >
        <template #selection="{ item }">
          <div
            class="d-flex justify-center title"
            style="width: 100%"
            v-text="item.text"
          />
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ResponseField',
  props: {
    value: {
      type: String,
      default: null,
    },
    answerList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    items () {
      return this.answerList.map(answer => ({
        text: answer.value,
        value: answer.key,
      }))
    },
  },
}
</script>
