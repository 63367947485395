<template>
  <question-container
    :question="game.questionOne"
    :answer="currentPlayer.answerOne"
    :answer-list="categoryList"
    :action-on-submit="actionOnSubmit"
    :waiting-message="$t('ontime.waitNextQuestionMessage')"
    waiting-color="accent"
    picture="question-one.jpg"
  />
</template>

<script>
import Helpers from 'src/utils/helpers'
import QuestionContainer from './QuestionContainer'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'QuestionOneContainer',
  components: {
    QuestionContainer,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    actionOnSubmit () {
      return AVAILABLE_ACTIONS.answerQuestionOne
    },
    categoryList () {
      const messages = this.$i18n.messages[this.$i18n.locale]
      return Helpers.getKeyValueList(messages.ontime.choiceList)
    },
  },
}
</script>
