<template>
  <v-container fluid>
    <v-overlay :value="hasAnswered" absolute>
      <div
        :class="[waitingColor]"
        class="pa-6 headline rounded-lg"
        v-text="waitingMessage"
      />
    </v-overlay>

    <v-card class="ma-10">
      <v-card-text class="fill-height">
        <v-row align="center" justify="center" class="fill-height">
          <v-col
            cols="12"
            sm="8"
            md="6"
            lg="5"
            xl="4"
            class="text-center"
          >
            <v-img
              :src="require(`@/assets/images/ontime/${picture}`)"
              class="rounded-lg my-10"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="5"
            xl="4"
            class="text-center"
          >
            <question-alert :value="question" />

            <response-field
              v-model="internalAnswer"
              :answer-list="answerList"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="loading"
          :disabled="loading || !internalAnswer || hasAnswered"
          color="accent"
          @click="sendAnswer"
          v-text="buttonText"
        />
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import QuestionAlert from '../components/QuestionAlert'
import ResponseField from '../components/ResponseField'

export default {
  name: 'QuestionOneContainer',
  components: {
    QuestionAlert,
    ResponseField,
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      default: null,
    },
    answerList: {
      type: Array,
      required: true,
    },
    actionOnSubmit: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    waitingMessage: {
      type: String,
      required: true,
    },
    waitingColor: {
      type: String,
      default: 'accent',
    },
  },
  data: () => ({
    internalAnswer: '',
    loading: false,
  }),
  inject: [
    'sendPlayerAction',
  ],
  computed: {
    hasAnswered () {
      return !!this.answer
    },
    buttonText () {
      return this.hasAnswered
        ? this.$t('ontime.waitNextQuestionButton')
        : this.$t('general.validate')
    },
  },
  watch: {
    answer: {
      immediate: true,
      handler () {
        this.internalAnswer = this.answer
      },
    },
  },
  methods: {
    async sendAnswer () {
      this.loading = true
      await this.sendPlayerAction(this.actionOnSubmit, {
        answer: this.internalAnswer,
      })
      this.loading = false
    },
  },
}
</script>
